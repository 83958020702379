<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">
          <h5 class="card-label">Features</h5>
        </div>

        <hr />
        <div
          class="d-flex flex-wrap align-items-center card-body"
          style="gap: 1rem"
        >
          <b-card
            v-for="feature in feature_flags"
            :key="feature.feature_flag_id"
            class="feature-card"
          >
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title m-0">{{ feature.ffl_nome }}</h5>
              <b-badge
                v-if="feature.ffl_disponivel"
                variant="success"
                class="mb-2"
              >
                Em produção
              </b-badge>
            </div>

            <p class="mt-3">{{ feature.ffl_descricao }}</p>
            <div class="d-flex align-items-center">
              <span class="mr-2"> Status: </span>
              <InputSwitch
                v-model="feature.ffl_ativo"
                @change="toggleFeatureFlag(feature.feature_flag_id)"
              />
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputSwitch from "primevue/inputswitch";
import API_LINKS from "./automacao/api.links";
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    InputSwitch
  },
  data() {
    return {
      feature_flags: []
    };
  },
  methods: {
    getFeatureFlags() {
      ApiService.get(API_LINKS.feature_flags).then((res) => {
        console.log(res.data.features);
        this.feature_flags = res.data.features;
      });
    },
    toggleFeatureFlag(feature_flag_id) {
      ApiService.post(API_LINKS.feature_flags + "/toggle", {
        id: feature_flag_id
      }).then((res) => {
        console.log(res);
      });
    }
  },
  mounted() {
    this.getFeatureFlags();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Feature Flags" }]);
  }
};
</script>

<style lang="scss" scoped>
.feature-card {
  max-width: 32%;
  @media (max-width: 768px) {
    max-width: 100%;
  }
}
</style>
